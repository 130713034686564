export const minPrice = 199;

export const cdnStats = {
  capacity: '200 Tbps',
  dailyTraffic: '310 PB',
  backboneCapacity: '20 Tbps',
  trafficPeakNA: '20 Tbps',
  trafficPeak: '60 Tbps',
  httpRequests: '4,000,000+',
  websitesCount: '45,000',
  teamMembers: '113',
  avgCashHit: '98%',
  requests: '160,000+',
  netflowsAnalyzed: '600,000+',
  monitoredConnections: '150,000+',
  ispConnected: '3 000',
  pnis: '300+',
  videoPercentage: '~90%',
  tier1TransitProviders: 16,
  continentsCount: 6,
  locationsCount: 119,
} as const;

export const support = {
  email: 'support@cdn77.com',
  telegram: 'cdn77support',
  phone: { uk: '+44(0) 117 318 3976' },
  workingHours: '8-15 (GMT)',
} as const;

export const sales = {
  email: 'sales@cdn77.com',
  telegram: 'Katerina_CDN77',
  phone: { uk: '+44(0) 20 3808 5949', us: '+1 888-354-7133' },
  workingHours: '8-16 (GMT)',
} as const;

export const abuse = {
  email: 'abuse@datacamp.co.uk',
} as const;

export const convertTelToHref = (phone: string) => `tel:${phone.replace(/ |\(0\)/g, '')}`;

export const skype = 'Skype:live:.cid.b5f6f3e971d06828?chat';

export const datacampAddress = {
  street: '9 Coldbath Square',
  city: 'London',
  cityDistrict: 'Clerkenwell',
  postalCode: 'EC1R 5HL',
  country: 'United Kingdom',
  countryCode: 'UK',
} as const;
